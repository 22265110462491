<template>
  <div class="white mainBlack--text rounded gotham-font">
    <div class="d-sm-flex align-center mb-10" :class="[$vuetify.breakpoint.smAndDown ? 'pa-2' : 'px-4  py-4']">
      <div @click="nav()" class="flex d-flex align-center pointer">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }"> -->

        <!-- <v-icon class="icon text-h2 mainRed--text">{{ icon }}</v-icon> -->
        <div class="align-self-center" :class="[$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4']">
          {{ title }}
          <div v-if="showDateCreated && showSubtitle" class="text-body-2">Created on {{ app.DateCreated | date }}</div>
          <div v-if="!showDateCreated && showSubtitle" class="text-body-1">
            {{ app.yearMakeModel }}
          </div>
        </div>

        <!-- </template>
          <span>Click to go back to the Registration Status page</span>
        </v-tooltip> -->
      </div>
      <div
        class="rounded text-h6 white--text px-3 py-2"
        :class="[$vuetify.breakpoint.xsOnly ? 'mt-4' : '', app.Status === 'Draft' ? 'error' : 'success']"
      >
        {{ app.Status === 'Draft' ? 'Not Submitted' : app.Status }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import VueRouter from '../../router';

export default {
  name: 'RegistrationHeader',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showDateCreated: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSubtitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({}),
  methods: {
    nav() {
      const baseRegistrationName = 'app.dashboard';
      VueRouter.push({ name: baseRegistrationName, params: { regId: this.app.RegistrationID } });
    },
  },
  computed: {
    app() {
      return this.$store.state.registration.app;
    },
  },
};
</script>

<style scoped>
.icon {
  width: 80px;
  padding: 0 15px 0 0;
}
</style>
